import { Injectable } from '@angular/core';
import { Global } from './global';

declare var $;

@Injectable()
export class Karix {

  myNumber;
  conversaciones;

  async getNumber() {

    let global = new Global(this);

    this.myNumber = await global.apiCall('getNumber', 'GET', true);

  }

  async getMensajes() {

    await this.getNumber();

    let global = new Global(this);

    return global.apiCall('getMessages', 'GET', true);

  }

  async enviarMensaje(mensaje, type='text') {

    await this.getNumber();

    mensaje.source = this.myNumber;

    let global = new Global(this);

    return global.apiCall('sendMessage', 'POST', true, { message: mensaje, type: type });

  }

  async getConversacionesWhatsapp(first = !this.conversaciones) {

    await this.getNumber();

    let result = await this.getMensajes();

    if(result.error) {
      
      alert('KARIX ERROR -> ' + result.error);
      return;

    }

    if (!result.objects) return;

    let whatsAppMsgs = [];

    for (let obj of result.objects) {

      // let n = '+34650579086';
      if (obj.channel != "whatsapp") continue;
      if (obj.error) continue;
      if(obj.destination != this.myNumber && obj.source != this.myNumber) continue;

      whatsAppMsgs.push(obj);

    }

    whatsAppMsgs.sort((a, b) => new Date(a.created_time).getTime() - new Date(b.created_time).getTime());

    let contacts = [];

    for (let msg of whatsAppMsgs) {

      if (contacts.indexOf(msg.source) == -1 && msg.source != this.myNumber) contacts.push(msg.source);
      if (contacts.indexOf(msg.destination) == -1 && msg.destination != this.myNumber) contacts.push(msg.destination);

    }

    let conversaciones = {};

    for (let contact of contacts) {

      conversaciones[contact] = {
        mensajes: []
      }

    }

    for (let msg of whatsAppMsgs) {

      let contact = msg.destination;

      if (contact == this.myNumber) contact = msg.source;

      conversaciones[contact].mensajes.push(msg);

    }

    if (first) this.conversaciones = conversaciones;

    if (!first) {

      //Primero añado las nuevas conversaciones
      for (let conversacion in conversaciones) {

        if (!this.conversaciones[conversacion]) {

          this.conversaciones[conversacion] = conversaciones[conversacion];

        }

      }


      for (let conversacion in this.conversaciones) {

        //Añado los nuevos mensajes
        for (let i = this.conversaciones[conversacion].mensajes.length; i < conversaciones[conversacion].mensajes.length; i++) {

          this.conversaciones[conversacion].mensajes[i] = conversaciones[conversacion].mensajes[i];

        }
        //Actualizo el estado
        for (let i = 0; i < this.conversaciones[conversacion].mensajes.length; i++) {

          this.conversaciones[conversacion].mensajes[i].status = conversaciones[conversacion].mensajes[i].status;

        }

      }

    }

    return this.conversaciones;

  }

}