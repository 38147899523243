import { Component, OnInit } from '@angular/core';
import { Global } from '../global';
import { Router } from '@angular/router';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  cargado = false;
  email;
  password;
  remember;
  error;

  constructor(private global: Global, private router: Router) { }

  async ngOnInit() {

    //Comprobamos si existe un token guardado, y si no ha expirado
    if(await this.global.checkToken()) {

      //Si existe redirigimos al chat

      this.router.navigateByUrl('/chat');

    } else {

      //Si no existe, reseteamos los campos y mostramos el formulario

      this.reset();

      this.cargado = true;

    }

  }

  reset() {

    this.email = {
      value: '',
      isValid: null
    };
    this.password = {
      value: '',
      isValid: null
    };
    this.remember = false;

  }

  async login() {

    if(this.global.config.debug) console.log('login');

    this.error = false;

    this.email.isValid = !(!this.email.value || this.email.value == '') && this.validEmail(this.email.value);
    this.password.isValid = !(!this.password.value || this.password.value == '');

    if (!this.email.isValid || !this.password.isValid) return;

    try {
      
      // Consulta ajax para comprobar si es un usuario válido
      let result = await this.global.apiCall('auth/login', 'POST', false, { email: this.email.value, password: this.password.value, remember_me: this.remember });
      if(this.global.config.debug) console.log('auth/login', result);

      // Si sigue es que es correcto, si no entraria en el catch

      // Almacenamos el token
      this.global.setToken(result);

      this.router.navigateByUrl('/chat');

    } catch (err) {

      this.error = true;
      return;

    }

    // let result = await this.global.apiCall('auth/user','GET', true);
    // console.log('r', result);

  }

  validEmail(email) {

    let check1 = email.split('@').length == 2;

    if (check1) {

      let check2 = email.split('@')[1].split('.').length >= 2;

      return check2;

    }

    return false;

  }

}
