import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { Karix } from './karix';
import Pusher from 'pusher-js';

declare var $;

@Injectable()
export class Global {

    VERSION = 1.25;

    config;
    usuario;

    pusher;
    channel;

    constructor(private karix: Karix) { }

    async get_config() {

        this.config = await this.readJson('config');

        if(!this.config.limite_mensajes) this.config.limite_mensajes = 100;

        if(!this.config.limite_casos) this.config.limite_casos = 20;

        if(!this.config.template) this.config.template = "Hola, ya volvemos a estar operativos. ¿Te va bien comentarlo ahora?";

        if(this.config.debug) console.log('Config', this.config);

    }

    async init_pusher() {

        this.pusher = new Pusher(this.config.websocket_key, {
            cluster: 'eu',
            forceTLS: true
        });

        this.channel = this.pusher.subscribe('whatsapp');

    }

    async apiCall(url, method, auth = false, data = null) {

        if (!this.config) this.config = await this.readJson('config');

        let headers = {
            "Content-Type": "application/json",
            "X-Requested-With": "XMLHttpRequest"
        };

        if (auth) {

            let token = JSON.parse(localStorage.getItem('ERSM_TOKEN'));

            if (!token) return;

            let access_token = token.access_token;

            headers['Authorization'] = "Bearer " + access_token;

        }

        let settings = {
            url: this.config.api + url,
            method: method,
            headers: headers,
            error: function (XMLHttpRequest, textStatus, errorThrown) {
                // alert("Error al comunicarse con API REST: " + url);
            }
        };

        if (data) {

            settings['data'] = JSON.stringify(data)

        }

        return await $.ajax(settings);

    }

    setToken(token) {

        // Almacenamos el token en el localStorage
        localStorage.setItem('ERSM_TOKEN', JSON.stringify(token));

    }

    async checkToken() {

        if (!localStorage.getItem('ERSM_TOKEN')) return false;

        let token = JSON.parse(localStorage.getItem('ERSM_TOKEN'));

        let expires_at = new Date(token.expires_at);
        expires_at.setHours(expires_at.getHours() + 2);

        let now = new Date();

        let moment_expires_at = moment(expires_at);
        let moment_now = moment(now);

        let logged = moment_expires_at.isAfter(moment_now);
        let result;

        if (logged) {

            try {

                result = await this.apiCall('auth/user', 'GET', true);

            } catch (e) {

                return false;

            }

            this.usuario = result;

            setTimeout(() => {

                if (window.innerWidth < 600) $('#accordionSidebar').addClass("toggled");

                window.addEventListener("resize", () => {

                    if (window.innerWidth < 600) $('#accordionSidebar').addClass("toggled");

                });

            }, 0);

        }

        return logged;

    }

    async checkVersion() {

        let apiVersion = await this.apiCall('version?app=WHATSAPP', 'GET', false);

        if (apiVersion != this.VERSION) location.reload();

        if(this.config.debug) console.log('Version', apiVersion);

    }

    async readJson(JSON) {

        let headers = {
            "Content-Type": "application/json"
        };

        let settings = {
            cache: false,
            url: "assets/json/" + JSON + ".json",
            method: 'GET',
            headers: headers
        };

        return await $.ajax(settings);

    }

}