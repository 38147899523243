import { Component, OnInit } from '@angular/core';
import { Global } from '../global';
import { Router, ActivatedRoute } from '@angular/router';
import * as moment from 'moment';

declare var $;

@Component({
  selector: 'app-listado',
  templateUrl: './listado.component.html',
  styleUrls: ['./listado.component.scss']
})
export class ListadoComponent implements OnInit {

  tipoListado;
  cargado = false;

  table;

  fullData;
  columns;

  nuevo;
  err;

  filtros;
  filtros_send = {};
  tipoFiltro = {
    ERROR: 0,
    TEXT: 1,
    NUMBER: 2,
    DATE: 3,
    DATETIME: 4,
    TIME: 5
  };

  dias = [
    'Lunes', 'Martes', 'Miercoles', 'Jueves', 'Viernes', 'Sábado', 'Domingo'
  ];

  num_registros_opciones = [10, 50, 100];
  num_registros = this.num_registros_opciones[0];
  num_paginas = 1;
  pagina = 1;
  busqueda = "";

  constructor(private global: Global, private router: Router, private activatedRoute: ActivatedRoute) {

    this.activatedRoute.params.subscribe(async params => {

      if (this.tipoListado != this.activatedRoute.params['_value'].tipoListado) await this.reset();

    })

  }

  async ngOnInit() {

    //Comprobamos si existe un token guardado, y si no ha expirado
    if (!await this.global.checkToken()) {

      //Si no existe redirigimos al login

      this.router.navigateByUrl('/');

    } else {

      await this.reset();

    }

  }

  irChat(item) {

    this.router.navigateByUrl('/chat/' + item.id);

  }

  async reset(force = false) {

    if (this.tipoListado == this.activatedRoute.params['_value'].tipoListado && !force) return;

    this.nuevo = null;
    this.err = null;

    if (this.global.config.debug) console.log(this.tipoListado, this.activatedRoute.params['_value'].tipoListado);

    if (this.global.config.debug) console.log('Table', this.table);

    if (this.table) this.table.destroy();

    this.table = null;

    this.tipoListado = this.activatedRoute.params['_value'].tipoListado;

    this.cargado = false;

    if (!force) this.pagina = 1;

    if (!force) await this.getFiltros();

    this.busqueda = "";

    await this.filtrar();

    if (this.tipoListado == 'canales') {

      this.nuevo = {};

    }

    if (this.tipoListado == 'usuarios') {

      this.nuevo = {};

    }

    if (this.tipoListado == 'automessage') {

      this.nuevo = {};

    }

    if (this.tipoListado == 'programmedmessages') {

      this.nuevo = {};

    }

    this.columns = [];
    if (this.fullData.length > 0) for (let key in this.fullData[0]) { this.columns.push(key); }

    if (this.tipoListado == 'usuarios' && this.global.usuario) {

      let usuario = this.fullData.find(u => u.email == this.global.usuario.email);

      if (this.global.usuario.email != usuario.email) this.global.usuario.email = usuario.email;

      let admin = usuario.Tipo == 'Administrador';

      if (this.global.usuario.admin != admin) this.global.usuario.admin = admin;

      if (!admin) {

        this.router.navigateByUrl('/chat');

      }

    }

    this.cargado = true;

    setTimeout(() => {

      $('.select2').each(function () {

        let html = $(this).html();
        $(this).html('');

        $(this).attr('multiple', 'multiple');
        $(this).select2();

        $(this).html(html);

      });

      $('.datepicker').datepicker({ dateFormat: 'dd/mm/yy' });
      $('.datetimepicker').datetimepicker({ format: 'yyyy-mm-dd hh:ii' });

      // this.drawTable();

    }, 100);

  }

  async edit(item) {

    if (this.global.config.debug) console.log('Edit', item);

    this.nuevo = JSON.parse(JSON.stringify(item));

    if (this.nuevo.Tipo) this.nuevo.admin = this.nuevo.Tipo == 'Administrador';

    let periodo = this.nuevo.periodo;

    if (periodo) this.nuevo.periodico = true;

    let that = this;
    let desde = this.nuevo.desde;
    let hasta = this.nuevo.hasta;

    $('#addModal').modal('toggle');

    setTimeout(() => {

      $('.select2').each(function () {

        let html = $(this).html();
        $(this).html('');

        $(this).attr('multiple', 'multiple');
        $(this).select2();


        $(this).html(html);

        if (periodo) {

          setTimeout(() => {

            $(this).val(periodo.split(';'));
            $(this).trigger('change');

          }, 1000);

          let d = new Date(desde).toLocaleTimeString('es-ES');
          let h = new Date(hasta).toLocaleTimeString('es-ES');

          if (new Date(desde).getHours() < 10) d = "0" + d;
          if (new Date(hasta).getHours() < 10) h = "0" + h;

          that.nuevo.desde = d;
          that.nuevo.hasta = h;

          // $('#mdesde').val(new Date(desde).toLocaleTimeString('es-ES').substring(0, new Date(desde).toLocaleTimeString('es-ES').length - 2));
          // $('#mhasta').val(new Date(hasta).toLocaleTimeString('es-ES').substring(0, new Date(hasta).toLocaleTimeString('es-ES').length - 2));

        }

      });

      $('.datepicker').datepicker({ dateFormat: 'dd/mm/yy' });
      $('.datetimepicker').datetimepicker({ format: 'yyyy-mm-dd hh:ii' });

    }, 100);

  }

  setPeriodico() {

    setTimeout(() => {

      let periodico = this.nuevo.periodico;

      if (!periodico) this.nuevo.periodo = "";

      let periodo = this.nuevo.periodo;

      let that = this;

      $('.select2').each(function () {

        let html = $(this).html();
        $(this).html('');

        $(this).attr('multiple', 'multiple');
        $(this).select2();


        $(this).html(html);

        if (periodo)
          setTimeout(() => {

            $(this).val(periodo.split(';'));
            $(this).trigger('change');

          }, 1000);

        that.nuevo.desde = "";
        that.nuevo.hasta = "";

      });

      $('.datepicker').datepicker({ dateFormat: 'dd/mm/yy' });
      $('.datetimepicker').datetimepicker({ format: 'yyyy-mm-dd hh:ii' });

    }, 0);

  }

  getCelda(item, column) {

    if (item.periodo && (column == 'desde' || column == 'hasta') && item.periodo != "") return new Date(item[column]).toLocaleTimeString('es-ES');

    if (item.periodo && (column == 'desde' || column == 'hasta') && item.periodo == "") return new Date(item[column]).toLocaleString('es-ES');

    if (column == 'periodo') {

      if (!item[column]) return 'VACIO';

      if (item[column] == 'VACIO') return 'VACIO';

      let periodo = item[column].split(';');
      let aux = "";
      for (let i = 0; i < periodo.length; i++) {

        aux += this.dias[periodo[i] - 1];

        if (i < periodo.length - 1) aux += ', ';

      }
      return aux;

    }

    return item[column];

  }

  async delete(item) {

    let txtConfirm = '¿Estas seguro?';

    if (item.Casos && item.Casos > 0) txtConfirm += ' Los casos asociados se asignaran a "Sin asignar"';

    if (!confirm(txtConfirm)) return;

    item['tipoListado'] = this.tipoListado;

    if (this.global.config.debug) console.log('Delete', item);

    let result = await this.global.apiCall('deleteFromListado', 'POST', true, item);
    if (this.global.config.debug) console.log('deleteFromListado', result);

    await this.reset(true);

  }

  async getData() {

    let data = {
      tipoListado: this.tipoListado,
      num_registros: this.num_registros,
      pagina: this.pagina,
      busqueda: this.busqueda,
      filtros: this.filtros_send
    };

    let result = await this.global.apiCall('listado', 'POST', true, data);
    if (this.global.config.debug) console.log('listado', result);

    this.num_paginas = Math.ceil(result.n / this.num_registros);

    this.fullData = result.data;

  }

  async setPagina(p) {

    this.pagina = p;

    await this.getData();

  }

  async set_num_registros() {

    this.pagina = 1;

    await this.getData();

  }

  async buscar() {

    this.pagina = 1;

    await this.getData();

  }

  paginas() {

    let aux = [];

    for (let i = 0; i < this.num_paginas; i++) aux.push(i + 1);

    return aux;

  }

  addModal() {

    this.nuevo = {};

    setTimeout(() => {

      $('.select2').each(function () {

        let html = $(this).html();
        $(this).html('');

        $(this).attr('multiple', 'multiple');
        $(this).select2();

        $(this).html(html);

      });

      $('.datepicker').datepicker({ dateFormat: 'dd/mm/yy' });
      $('.datetimepicker').datetimepicker({ format: 'yyyy-mm-dd hh:ii' });

    }, 100);

  }

  getTituloListado() {

    if (this.tipoListado == 'automessage') return 'horarios';

    if (this.tipoListado == 'programmedmessages') return 'mensajes programados';

    return this.tipoListado;

  }

  async getFiltros() {

    this.filtros = {};
    this.filtros_send = null;

    let columns = [];

    if (this.tipoListado == 'canales') {

      columns = [
        {
          filtro: "id",
          tipo: this.tipoFiltro.NUMBER
        }, {
          filtro: "Casos",
          tipo: this.tipoFiltro.NUMBER
        }, {
          filtro: "nombre",
          tipo: this.tipoFiltro.TEXT
        }
      ];

    } else if (this.tipoListado == 'usuarios') {

      columns = [
        {
          filtro: "id",
          tipo: this.tipoFiltro.NUMBER
        }, {
          filtro: "Tipo",
          tipo: this.tipoFiltro.TEXT
        }, {
          filtro: "email",
          tipo: this.tipoFiltro.TEXT
        }
      ];

    } else if (this.tipoListado == 'conversaciones') {

      columns = [
        {
          filtro: "id",
          tipo: this.tipoFiltro.NUMBER
        }, {
          filtro: "telefono",
          tipo: this.tipoFiltro.TEXT
        }, {
          filtro: "Cliente",
          tipo: this.tipoFiltro.TEXT
        }, {
          filtro: "canal",
          tipo: this.tipoFiltro.TEXT
        }, {
          filtro: "creado",
          tipo: this.tipoFiltro.DATE
        }, {
          filtro: "Estado",
          tipo: this.tipoFiltro.TEXT
        }, {
          filtro: "mensajes",
          tipo: this.tipoFiltro.NUMBER
        }
      ];

    } else if (this.tipoListado == 'programmedmessages') {

      columns = [
        {
          filtro: "id",
          tipo: this.tipoFiltro.NUMBER
        }, {
          filtro: "fecha",
          tipo: this.tipoFiltro.DATETIME
        }, {
          filtro: "telefono",
          tipo: this.tipoFiltro.TEXT
        }, {
          filtro: "mensaje",
          tipo: this.tipoFiltro.TEXT
        }
      ];

    } else if (this.tipoListado == 'automessage') {

      columns = [
        {
          filtro: "id",
          tipo: this.tipoFiltro.NUMBER
        }, {
          filtro: "fdesde",
          tipo: this.tipoFiltro.DATE
        }, {
          filtro: "fhasta",
          tipo: this.tipoFiltro.DATE
        }, {
          filtro: "hdesde",
          tipo: this.tipoFiltro.TIME
        }, {
          filtro: "hhasta",
          tipo: this.tipoFiltro.TIME
        }, {
          filtro: "mensaje",
          tipo: this.tipoFiltro.TEXT
        }, {
          filtro: "periodo",
          tipo: this.tipoFiltro.TEXT
        }
      ];

    }

    let data = {
      tipoListado: this.tipoListado,
      columns: columns
    };

    let filtros = await this.global.apiCall('get_filtros_listado', 'POST', true, data);
    if (this.global.config.debug) console.log('get_filtros_listado', filtros);

    for (let column of columns) {

      this.filtros[column.filtro] = {
        type: column.tipo,
        selected: [],
        min: '',
        max: '',
        exclude: false,
        options: []
      };

      if (column.filtro == 'periodo') {

        this.filtros[column.filtro].options.push('VACIO');

        for (let dia of this.dias) this.filtros[column.filtro].options.push(dia);

      } else {

        if (!filtros[column.filtro]) continue;

        for (let filtro of filtros[column.filtro]) {

          this.filtros[column.filtro].options.push(filtro);

        }

      }

    }

  }

  async filtrar() {

    for (let filtro in this.filtros) {

      if (this.filtros[filtro].type == this.tipoFiltro.TEXT) {

        this.filtros[filtro].selected = $('#' + filtro + ' select').val();

      }

      if (this.filtros[filtro].type == this.tipoFiltro.DATE) {

        this.filtros[filtro].min = $('#' + filtro + ' .datepicker:nth-of-type(1)').val();
        this.filtros[filtro].max = $('#' + filtro + ' .datepicker:nth-of-type(2)').val();

      }

      if (this.filtros[filtro].type == this.tipoFiltro.DATETIME) {

        this.filtros[filtro].min = $('#' + filtro + ' .datetimepicker:nth-of-type(1)').val();
        this.filtros[filtro].max = $('#' + filtro + ' .datetimepicker:nth-of-type(2)').val();

      }

    }

    this.filtros_send = {};

    for (let filtro in this.filtros) {

      this.filtros_send[filtro] = {
        selected: this.filtros[filtro].selected || [],
        min: this.filtros[filtro].min || "",
        max: this.filtros[filtro].max || "",
        type: this.filtros[filtro].type,
        exclude: this.filtros[filtro].exclude
      }
    }

    this.pagina = 1;

    await this.getData();

  }

  async add() {

    if (this.tipoListado == 'usuarios' && !this.nuevo.id) {

      try {

        let result = await this.global.apiCall('auth/signup', 'POST', true, this.nuevo);
        if (this.global.config.debug) console.log('addUsuario', result);

      } catch (err) {

        let error = err.responseJSON;

        if (this.global.config.debug) console.log(error);

        this.err = error.errors;

        return;

      }

    } else {

      if (this.nuevo.password) {

        if (!this.nuevo.password_confirmation || this.nuevo.password != this.nuevo.password_confirmation) {

          this.err = {};
          this.err.password = 'El campo confirmación de password no coincide';
          return;

        }

      }

      if (this.nuevo.email) {

        if (!this.ValidateEmail(this.nuevo.email)) {

          this.err = {};
          this.err.email = 'El campo email debe ser una dirección de correo válida.';
          return;

        }

      }

      this.nuevo['tipoListado'] = this.tipoListado;

      if (this.tipoListado == 'programmedmessages') {

        this.nuevo.fecha = document.getElementById('mfecha')['value'];

        this.err = {};

        if (this.nuevo.fecha == "") {

          this.err.fecha = "Rellena el campo";
          return;

        }

        if (!this.nuevo.telefono || $.trim(this.nuevo.telefono) == "") {

          this.err.telefono = "Rellena el campo";
          return;

        }

        if (!this.nuevo.mensaje || $.trim(this.nuevo.mensaje) == "") {

          this.err.mensaje = "Rellena el campo";
          return;

        }

      }

      if (this.tipoListado == 'automessage') {

        this.err = {}

        this.nuevo.desde = document.getElementById('mdesde')['value'];
        this.nuevo.hasta = document.getElementById('mhasta')['value'];
        this.nuevo.periodo = $('#mperiodo').val();

        this.nuevo.periodo = this.nuevo.periodo ? this.nuevo.periodo.join(';') : "";

        if (this.nuevo.periodico && this.nuevo.periodo == "") {

          this.err.periodo = "No has añadido ningún dia";
          return;

        }

        if (this.nuevo.desde == "") {

          this.err.desde = "Rellena el campo";
          return;

        }

        if (this.nuevo.hasta == "") {

          this.err.hasta = "Rellena el campo";
          return;

        }

        if (!this.nuevo.mensaje || $.trim(this.nuevo.mensaje) == "") {

          this.err.mensaje = "Rellena el campo";
          return;

        }

      }

      if (this.tipoListado == 'canales') {

        if (!this.nuevo.nombre || $.trim(this.nuevo.nombre) == "") {

          this.err = {
            nombre: "Rellena el nombre"
          };

          return;

        }

      }

      if (this.global.config.debug) console.log('addListado', this.nuevo);
      let result = await this.global.apiCall('addListado', 'POST', true, this.nuevo);
      if (this.global.config.debug) console.log('addListado', result);

    }

    $('#addModal').modal('hide');

    await this.reset(true);

  }

  drawTable() {

    if (this.table) return;

    this.table = $('#dataTable_' + this.tipoListado).DataTable({
      lengthMenu: [20, 50, 100],
      language: {
        url: "//cdn.datatables.net/plug-ins/1.10.15/i18n/Spanish.json"
      },
      initComplete: () => {

        $('.dataTables_info').remove();

      }
    });

  }

  ValidateEmail(mail) {
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(mail)) {
      return true
    }
    return false
  }

}
