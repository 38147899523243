import { Component, OnInit } from '@angular/core';
import { Global } from './global';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  pathname;
  toggled;
  cargado = false;

  constructor(public global: Global, private router: Router, private activatedRoute: ActivatedRoute) {

    this.activatedRoute.params.subscribe(async params => {

      this.pathname = window.location.pathname;

    })

  }

  async ngOnInit() { 

    await this.global.get_config();

    await this.global.init_pusher();

    await this.global.checkVersion();

    this.cargado = true;

  }

  isDashBoard() {

    return window.location.hash.indexOf('dashboard') != -1;

  }

  openDashboard() {

    let arr = [];

    let actualUrl = window.location.href;
    let actualUrlSplit = actualUrl.split('/');

    let url = '';
    for(let txt of actualUrlSplit) {

      url += txt + '/';

      if(txt == '#') break;

    }

    window.open(url + 'dashboard', "Dashboard", "width=800,height=700");

  }

  async cerrarSesion() {

    let result = await this.global.apiCall('auth/logout', 'GET', true);
    if(this.global.config.debug) console.log('auth/logout', result);

    localStorage.removeItem('ERSM_TOKEN');
    this.global.usuario = null;

    this.router.navigateByUrl('/');

  }

}
