import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';

// import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { Routes, RouterModule } from '@angular/router';

import { Global } from './global';
import { Karix } from './karix';
import { LoginComponent } from './login/login.component';
import { ChatComponent } from './chat/chat.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { ListadoComponent } from './listado/listado.component';

const routes: Routes = [
  {
    path: '',
    component: LoginComponent
  }, {
    path: 'chat',
    component: ChatComponent
  }, {
    path: 'chat/:caso',
    component: ChatComponent
  }, {
    path: 'dashboard',
    component: DashboardComponent
  }, {
    path: 'listado/:tipoListado',
    component: ListadoComponent
  },{
    path: '**',
    component: LoginComponent
  }
];

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    ChatComponent,
    DashboardComponent,
    ListadoComponent
  ],
  imports: [
    BrowserModule,
    RouterModule.forRoot(routes, { useHash: true }),
    FormsModule
  ],
  providers: [Global, Karix],
  bootstrap: [AppComponent]
})
export class AppModule { }
