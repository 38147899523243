import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { Global } from '../global';
import { Router, ActivatedRoute } from '@angular/router';
import { Karix } from '../karix';

declare var $;

@Component({
  selector: 'app-chat',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.scss']
})
export class ChatComponent implements OnInit {

  messageToDelete;
  contactos = [];
  mensajeTexto;
  cargado = false;
  canales;
  canalSeleccionado = -1;

  casos = [];
  casoSeleccionado;
  casosAbiertos = true;

  nombreContacto;
  canalAsignar = 0;

  preSelectCase;
  caso_preseleccionado;

  search;

  mensajeSeleccionado;
  goToMensaje;
  scrollCaso = false;
  seleccionandoCaso = false;

  viewer;
  searched = false;

  limite_mensajes = this.global.config.limite_mensajes;
  limite_casos = this.global.config.limite_casos;

  cargando_casos;
  cargando_mensajes;
  cargando_mas_casos = false;
  cargando_mas_mensajes = false;
  buscando;
  buscado;
  casos_totales;

  messages_scroll_bottom;
  casos_scroll_top;

  primera_carga_mensajes = false;

  constructor(public global: Global, private router: Router, public karix: Karix, private activatedRoute: ActivatedRoute) {

    this.preSelectCase = this.activatedRoute.params['_value'].caso;

  }

  async ngOnInit() {

    if (!await this.global.checkToken()) {

      this.router.navigateByUrl('/');

    } else {

      await this.karix.getNumber();

      await this.getCanales();

      this.cargado = true;

      await this.reset();

    }

  }

  async reset() {

    if (this.preSelectCase) {

      this.caso_preseleccionado = await this.global.apiCall('preseleccionar_caso', 'POST', true, { caso_id: this.preSelectCase });

      this.casosAbiertos = this.caso_preseleccionado.cerrado == null;

      this.canalSeleccionado = this.caso_preseleccionado.canal;

    } else {

      this.canalSeleccionado = 0;

    }

    await this.get_casos();

    if (this.preSelectCase) this.seleccionar_caso(this.casos.find(caso => caso.id == this.preSelectCase));

    this.websocket_listener();

    this.casos_scroll_listener();

    this.preSelectCase = null;

  }

  get_fecha_caso(caso) {

    let ultimo_mensaje = this.getUltimoMensaje(caso);

    let created_time = this.datetimeToDate(ultimo_mensaje.created_time);

    let hoy = new Date();

    if (hoy.toDateString() == created_time.toDateString()) {

      let d = created_time.toLocaleTimeString('es-ES');

      return d.split(":")[0] + ':' + d.split(":")[1];

    } else {

      return created_time.toLocaleDateString('es-ES');

    }

  }

  get_fecha_mensaje(mensaje_index) {

    if (!this.casoSeleccionado) return;

    if (mensaje_index == 0 && this.getCasoById().mensajes.length < this.getCasoById().mensajes_total) return;

    if (mensaje_index == 0 && this.cargando_mensajes) return;

    if (mensaje_index >= this.getCasoById().mensajes.length) return;

    let mensaje = this.getCasoById().mensajes[mensaje_index];

    let created_time_mensaje = this.datetimeToDate(mensaje.created_time);

    if (mensaje_index == 0) return created_time_mensaje.toLocaleDateString('es-ES');

    let mensaje_anterior = this.getCasoById().mensajes[mensaje_index - 1];

    let created_time_mensaje_anterior = this.datetimeToDate(mensaje_anterior.created_time);

    let hoy = new Date();

    if (created_time_mensaje.toDateString() != created_time_mensaje_anterior.toDateString()) {

      if (hoy.toDateString() == created_time_mensaje.toDateString()) return "Hoy";

      return created_time_mensaje.toLocaleDateString('es-ES');

    }

    return null;

  }

  get_canal_mensaje(mensaje_index) {

    if (!this.casoSeleccionado) return;

    if (mensaje_index == 0 && this.getCasoById().mensajes.length < this.getCasoById().mensajes_total) return;

    if (mensaje_index == 0 && this.cargando_mensajes) return;

    if (mensaje_index >= this.getCasoById().mensajes.length) return;

    let mensaje = this.getCasoById().mensajes[mensaje_index];

    if (mensaje_index == 0) return this.canales.find(canal => canal.id == mensaje.canal).nombre;

    let mensaje_anterior = this.getCasoById().mensajes[mensaje_index - 1];

    if (mensaje.canal == mensaje_anterior.canal) return;

    return this.canales.find(canal => canal.id == mensaje.canal).nombre;

  }

  async get_casos() {

    this.cargando_casos = true;

    let data = {
      canal: this.canalSeleccionado,
      casos_abiertos: this.casosAbiertos,
      min: this.limite_casos - this.global.config.limite_casos,
      limite_casos: this.global.config.limite_casos
    };

    let result = await this.global.apiCall('get_casos', 'POST', true, data);

    if (!this.cargando_mas_casos) this.casos = result.data;

    if (this.cargando_mas_casos) {

      for (let caso of result.data) {

        if (this.casos.find(c => c.id == caso.id)) continue;

        this.casos.push(caso);

      }

    }

    this.casos_totales = result.casos_totales;

    if (this.preSelectCase) {

      let aux = this.casos.find(caso => caso.id == this.preSelectCase);

      if (!aux) {

        this.casos.push(this.caso_preseleccionado);

      }

    }

    this.cargando_casos = false;

  }

  async get_mensajes(f = false) {

    if (!this.casoSeleccionado) return;

    this.cargando_mensajes = true;

    let data = {
      id_caso: this.casoSeleccionado,
      min: this.limite_mensajes - this.global.config.limite_mensajes,
      limite_mensajes: this.global.config.limite_mensajes
    };

    if (this.search) {

      data['mensaje_seleccionado'] = this.mensajeSeleccionado;

    }

    let mensajes = await this.global.apiCall('get_mensajes', 'POST', true, data);

    let caso = this.casos.find(caso => caso.id == this.casoSeleccionado);

    for (let i = mensajes.length - 1; i >= 0; i--) {

      let mensaje = mensajes[i];

      if (caso.mensajes.find(m => m.uid == mensaje.uid)) continue;

      caso.mensajes.unshift(mensaje);

    }

    caso.mensajes.sort(function (b, a) {

      return new Date(b.created_time).getTime() - new Date(a.created_time).getTime();

    });

    caso.mensajes_sin_leer = caso.mensajes.filter(mensaje => (!mensaje.status || mensaje.status == 'again') && mensaje.para == this.karix.myNumber).length;

    this.cargando_mensajes = false;

    if (!this.cargando_mas_mensajes) {

      if (f && !this.search) this.scrollToBottom();

      this.messages_scroll_listener();

    }

    this.primera_carga_mensajes = true;

  }

  messages_scroll_listener() {

    $('#messages').bind("DOMSubtreeModified", () => {

      let elemento = $('#messages')[0];

      elemento.scrollTop = elemento.scrollHeight - this.messages_scroll_bottom;

    });

    $('#messages').scroll(async () => {

      let elemento = $('#messages')[0];

      this.messages_scroll_bottom = elemento.scrollHeight - elemento.scrollTop;

      let proporcion = elemento.scrollTop / elemento.scrollHeight;

      if (proporcion <= 0.25 && !this.cargando_mensajes && this.getCasoById().mensajes.length < this.getCasoById().mensajes_total) {

        await this.ver_mas_mensajes();

      }

    });

  }

  casos_scroll_listener() {

    $('#chat-list').scroll(async () => {

      let elemento = $('#chat-list')[0];

      this.casos_scroll_top = elemento.scrollTop;

      let height = elemento.scrollHeight - elemento.offsetHeight;

      let scroll_bottom = height - elemento.scrollTop;

      let proporcion = scroll_bottom / height;

      if (proporcion <= 0.25 && this.casos.length < this.casos_totales && !this.cargando_casos && !this.search) {

        await this.ver_mas_casos();

      }

    });

  }

  caso_bloqueado() {

    if (!this.casoSeleccionado) return false;

    let caso = this.getCasoById();

    if (!caso) return false;

    if (caso.cerrado) return false;

    let mensajes_no_plantilla = caso.mensajes.filter(m => m.mensaje != this.global.config.template);

    let ultimo_mensaje_no_plantilla = mensajes_no_plantilla[mensajes_no_plantilla.length - 1];

    if (mensajes_no_plantilla.length == 0) return false;

    if (!ultimo_mensaje_no_plantilla) return false;

    let hours = this.get_horas_desde_mensaje(ultimo_mensaje_no_plantilla);

    let bloqueado = hours >= 24;

    return bloqueado;

  }

  get_horas_desde_mensaje(mensaje) {

    let now = new Date();

    return Math.abs(now.getTime() - new Date(mensaje.created_time).getTime()) / 36e5;

  }

  async seleccionar_caso(caso) {

    if (!caso) {

      this.casoSeleccionado = null;
      return;

    }

    if (this.casoSeleccionado == caso.id && this.getCasoById().mensajes.length >= this.global.config.limite_mensajes) return;

    if (this.casoSeleccionado && !this.search) this.getCasoById().mensajes = this.getCasoById().mensajes.slice(-1);

    this.casoSeleccionado = caso.id;

    this.limite_mensajes = this.global.config.limite_mensajes;

    this.mensajeTexto = "";

    this.primera_carga_mensajes = false;

    await this.get_mensajes(true);

    this.initKeyDetector();

    setTimeout(async () => {
      document.getElementById('input').addEventListener('keydown', function () {
        var el = this;
        setTimeout(function () { el.style.cssText = 'height:2.5em'; el.style.cssText = 'height:' + el.scrollHeight + 'px'; }, 0);
      });
    }, 0);

  }

  async ver_caso_completo() {

    this.preSelectCase = this.casoSeleccionado;

    this.search = null;

    this.buscado = false;

    this.mensajeSeleccionado = null;

    await this.reset();

  }

  async sincronizar_karix() {

    this.global.apiCall('sincronizar_karix', 'GET', true, null).then(
      result => {

        this.sincronizar_karix();

      }
    );

  }

  websocket_listener() {

    this.global.channel.bind('whatsapp', (result) => {

      if(this.global.config.debug) console.log('websocket', result);

      let type = result.type;
      let data = result.data;

      switch (type) {
        case "case":
          this.insertar_caso(data);
          this.alert_mensaje(data.mensajes[0], true);
          break;
        case "message":
          this.alert_mensaje(data);
          this.insertar_mensaje(data);
          break;
        case "status":
          this.actualizar_estado(data);
          break;
      }

    });

  }

  alert_mensaje(mensaje, f = false) {

    if (!mensaje) return;

    if (mensaje.deleted) return;

    if (mensaje.de == this.karix.myNumber && !f) return;

    let caso = this.casos.find(caso => caso.id == mensaje.caso);

    if (!caso) return;

    if (caso.cerrado) return;

    if (caso.canal != this.canalSeleccionado) return;

    let mensaje_index = caso.mensajes.findIndex(m => m.uid == mensaje.uid);

    if (mensaje_index != -1 && !f) return;

    let texto = "";

    texto += this.getContactoByTelefono(caso.telefono).cli_nombrecompleto + " (" + caso.telefono + "):\n";

    switch (mensaje.tipo) {
      case "text":
        texto += mensaje.mensaje;
        break;
      case "voice":
      case "audio":
        texto += "Audio";
        break;
      case "video":
        texto += "Video";
        break;
      case "image":
        texto += "Imágen";
        break;
      default:
        texto += "Nuevo mensaje";
        break;
    }

    alert(texto);

  }

  insertar_caso(caso) {

    if (!caso) return;

    let caso_index = this.casos.findIndex(c => c.id == caso.id);

    if (caso_index != -1) {

      if (caso.id == this.casoSeleccionado) this.casoSeleccionado = null;

      this.casos.splice(caso_index, 1);

      return;

    }

    if (caso.cerrado && this.casosAbiertos) return;

    if (caso.canal != this.canalSeleccionado) return;

    if (caso.mensajes.length == 0) return;

    this.casos.push(caso);

  }

  insertar_mensaje(message) {

    if (!message || !message.caso) return;

    let caso = this.casos.find(caso => caso.id == message.caso);

    if (!caso) return;

    let mensaje_index = caso.mensajes.findIndex(mensaje => mensaje.uid == message.uid);

    if (mensaje_index != -1) return;

    caso.mensajes.push(message);

    if (message.para == this.karix.myNumber) caso.mensajes_sin_leer++;

    caso.mensajes_total++;

    if (message.caso == this.casoSeleccionado) this.scrollToBottom();

    if (message.caso == this.casoSeleccionado && message.para == this.karix.myNumber) caso.mensajes[caso.mensajes.length - 1].status = 'read';

    if (message.caso == this.casoSeleccionado && message.para == this.karix.myNumber) this.global.apiCall('marcar_mensaje_leido', 'POST', true, { message_uid: message.uid });

  }

  actualizar_estado(message) {

    if (!message || !message.caso) return;

    let caso = this.casos.find(caso => caso.id == message.caso);

    if (!caso) return;

    if (!message.deleted) {

      if (!message.status || message.status == "again") {

        caso.mensajes_sin_leer++;

      } else {

        if(caso.mensajes_sin_leer > 0) caso.mensajes_sin_leer--;

      }

    }

    let mensaje_index = caso.mensajes.findIndex(mensaje => mensaje.uid == message.uid);

    if (mensaje_index == -1) return;

    caso.mensajes[mensaje_index].status = message.status;

    if (message.deleted) {

      caso.mensajes.splice(mensaje_index, 1);

      caso.mensajes_total--;

      if (caso.mensajes_total == 0 && caso.id == this.casoSeleccionado) this.casoSeleccionado = null;

    }

  }

  async ver_mas_mensajes() {

    this.cargando_mas_mensajes = true;

    this.limite_mensajes += this.global.config.limite_mensajes;

    await this.get_mensajes();

    this.cargando_mas_mensajes = false;

  }

  async ver_mas_casos() {

    this.cargando_mas_casos = true;

    this.limite_casos += this.global.config.limite_casos;

    await this.get_casos();

    this.cargando_mas_casos = false;

  }

  get_user_from_email(email) {

    return email.split("@")[0];

  }

  async getCanales() {

    let result = await this.global.apiCall('listado', 'POST', true, { tipoListado: 'canales' });

    this.canales = result.data;

  }

  async changeCanal() {

    this.casoSeleccionado = null;

    this.casos = [];

    this.limite_casos = this.global.config.limite_casos;

    await this.get_casos();

  }

  async changeCasosAbiertos(bool) {

    this.casosAbiertos = bool;

    this.casoSeleccionado = null;

    this.casos = [];

    this.limite_casos = this.global.config.limite_casos;

    await this.get_casos();

  }

  async enviarMensajeTexto() {

    if (this.caso_bloqueado()) {

      if (!confirm("¿Estas seguro?")) return;

      this.mensajeTexto = this.global.config.template;

    }

    if (!this.mensajeTexto || this.mensajeTexto == "") return;

    let mensaje = this.clone({
      "channel": "whatsapp",
      "destination": [
        this.getCasoById().telefono
      ],
      "content": {
        "text": this.mensajeTexto
      }
    });

    this.mensajeTexto = '';

    await this.enviarMensaje(mensaje);

  }

  abrir_cliente() {

    let caso = this.getCasoById();

    let contacto = this.getContactoByTelefono(caso.telefono);

    let es_cliente = !contacto.noEsContacto;

    if (!es_cliente) return;

    if (!contacto.cli_id) return;

    let url = this.global.config.url_cliente + contacto.cli_id;

    this.openInNewTab(url);

  }

  openInNewTab(url) {
    var win = window.open(url, '_blank');
    win.focus();
  }

  uploadFile(files) {

    var formData = new FormData();

    formData.append('select_file', files[0], files[0].name);

    return $.ajax({
      url: this.global.config.api + 'uploadFile',
      method: "POST",
      data: formData,
      dataType: 'JSON',
      contentType: false,
      cache: false,
      processData: false
    });

  }

  async enviarMensajeAdjunto(fileInput: any) {

    if (this.getCasoById().cerrado) {

      alert('No puedes enviar mensajes en un caso cerrado');
      return;

    }

    let files = <Array<File>>fileInput.target.files;

    let type = files[0].type.split('/')[0];

    let mensaje = this.clone({
      "channel": "whatsapp",
      "destination": [
        this.getCasoById().telefono
      ],
      "content": {
        "media": {
          "url": "",
        }
      }
    });

    let result = await this.uploadFile(files);

    let url = result.url;

    mensaje.content.media.url = url;

    await this.enviarMensaje(mensaje, type);

  }

  async marcar_no_leido() {

    let caso = this.getCasoById();

    let sus_mensajes = caso.mensajes.filter(m => m.para == this.karix.myNumber);

    let mensaje = sus_mensajes[sus_mensajes.length - 1];

    // mensaje.status = "again";

    // caso.mensajes_sin_leer++;

    this.global.apiCall('marcar_mensaje_no_leido', 'POST', true, { message_uid: mensaje.uid });

    this.casoSeleccionado = null;

  }

  async enviarMensaje(mensaje, mediaType = null) {

    if (this.getCasoById().cerrado) {

      alert('No puedes enviar mensajes en un caso cerrado');
      return;

    }

    let result = await this.karix.enviarMensaje(mensaje, mediaType);

    if (this.global.config.debug) console.log('enviarMensaje', result);

    if (result.error) {

      alert('KARIX ERROR -> ' + result.error.message);

      return;

    }

    this.insertar_mensaje(result);

  }

  limitText(text = '', limit = 10) {

    if (text.length > limit) return text.substr(0, limit) + '...';

    return text;

  }

  setViewer(mensaje = null) {

    if (!mensaje) {

      this.viewer = null;

    } else {

      this.viewer = {
        type: mensaje.tipo,
        url: mensaje.mensaje
      };

    }

  }

  ngOnDestroy() {

    this.canalSeleccionado = -1;

  }

  async searchBtn() {

    this.casoSeleccionado = null;

    this.search = !this.search ? { value: '' } : null;

    this.casos = [];

    this.buscado = false;

    if (this.search) setTimeout(function () { document.getElementById('search').focus(); }, 0);

    if (!this.search) await this.get_casos();

    if (!this.search) this.mensajeSeleccionado = null;

    if (!this.search) this.casos_scroll_listener();

  }

  searchMessages(caso = null) {

    if (!this.search) return [];

    if (caso) return caso.mensajes.filter(mensaje => mensaje.tipo == 'text' && mensaje.mensaje.toUpperCase().includes($.trim(this.search.value).toUpperCase()));

    let aux = [];

    for (let caso of this.casos) aux = this.arrMerge(aux, caso.mensajes.filter(mensaje => mensaje.tipo == 'text' && mensaje.mensaje.toUpperCase().includes($.trim(this.search.value).toUpperCase())));

    return aux;

  }

  get_fecha_width() {

    let w = $('#messages').width() * 0.95;

    return w + 'px';

  }

  arrMerge(arr1, arr2) {

    let aux = [];

    for (let item in arr1) aux.push(item);
    for (let item in arr2) aux.push(item);

    return aux;

  }

  conSaltosdeLinea(mensaje) {

    return mensaje.replace(/\n/g, "<br>");

  }

  async guardarContacto() {

    if (!this.nombreContacto || this.nombreContacto == '') {
      alert('No puedes dejar el campo vacio');
      return;
    }

    let contacto = {
      telefono: this.getCasoById().telefono,
      nombre: this.nombreContacto
    };

    let result = await this.global.apiCall('nuevoContacto', 'POST', true, contacto);
    if (this.global.config.debug) console.log('nuevoContacto', result);

    this.getCasoById().contacto = result;

    $('#addContactModal').modal('hide')

    this.nombreContacto = '';

  }

  async asignar_canal() {

    if (!this.casoSeleccionado) return;

    $('#addChannelModal').modal('hide');

    if (this.canalAsignar == this.canalSeleccionado) return;

    let data = this.clone({
      caso: this.casoSeleccionado,
      canal: this.canalAsignar
    });

    let caso_index = this.casos.findIndex(caso => caso.id == this.casoSeleccionado);

    this.casos.splice(caso_index, 1);

    this.casoSeleccionado = null;

    await this.global.apiCall('asignar_canal', 'POST', true, data);

  }

  initKeyDetector() {

    let map = {}; // You could also use an array
    onkeydown = onkeyup = (e: any) => {
      e = e || event; // to deal with IE
      map[e.keyCode] = e.type == 'keydown';
      /* insert conditional here */
      if (map[13] && map[17]) this.enviarMensajeTexto();
    }

  }

  deSelectMessage() {

    if (this.mensajeSeleccionado) this.mensajeSeleccionado = null;
    if (this.messageToDelete) this.messageToDelete = null;

  }

  async selectMessage(message, search = false, del = false) {

    this.mensajeSeleccionado = message.uid;

    await this.seleccionar_caso({ id: message.caso });

    setTimeout(() => {

      let messagesOffset = $('#messages').offset().top;

      $('#messages').animate({

        scrollTop: $('#' + this.mensajeSeleccionado).offset().top - messagesOffset

      }, 2000);

    }, 100);

  }

  getCasoById(id = this.casoSeleccionado) {

    return this.casos.find(caso => caso.id == id);

  }

  getCasosByCanal() {

    this.casos.sort(function (b, a) {

      return new Date(a.mensajes[a.mensajes.length - 1].created_time).getTime() - new Date(b.mensajes[b.mensajes.length - 1].created_time).getTime()

    });

    return this.casos;

  }

  async searchChange() {

    if (!this.search || !this.search.value || $.trim(this.search.value) == "") return;

    this.buscado = false;
    this.buscando = true;
    this.casoSeleccionado = null;

    let data = {
      search: this.search.value
    };

    this.casos = await this.global.apiCall('buscar_casos', 'POST', true, data);

    if (this.global.config.debug) console.log('Casos', this.casos);

    this.buscando = false;

    this.buscado = true;

  }

  delete_mensaje(i) {

    if (!confirm('¿Estás seguro?')) return;

    let mensaje = this.getCasoById().mensajes[i];

    this.getCasoById().mensajes.splice(i, 1);

    this.getCasoById().mensajes_total--;

    if (this.getCasoById().mensajes_total == 0) this.casoSeleccionado = null;

    this.global.apiCall('delete_mensaje', 'POST', true, { uid: mensaje.uid });

  }

  searchModelChange() {

    this.buscado = false;

  }

  clone(obj) {

    return JSON.parse(JSON.stringify(obj));

  }

  mensajesNuevos(caso) {

    if (caso.cerrado) return 0;

    if (caso.id == this.casoSeleccionado) return 0;

    return caso.mensajes_sin_leer || 0;

  }

  async cerrar_caso() {

    if (!this.casoSeleccionado) return;

    if (!confirm('¿Estas seguro?')) return;

    // let caso = this.getCasoById();

    // let ultimo_mensaje = caso.mensajes[caso.mensajes.length - 1];

    // let created_time = new Date(ultimo_mensaje.created_time);

    // let now = new Date();

    // let diff = now.valueOf() - created_time.valueOf();

    // let diff_horas = diff / 1000 / 60 / 60;

    // if (diff_horas < 24) {

    //   alert("No puedes cerrar un caso hasta pasadas 24 horas del ultimo mensaje");

    //   return;

    // }

    let data = this.clone({ caso: this.casoSeleccionado });

    let caso_index = this.casos.findIndex(caso => caso.id == this.casoSeleccionado);

    this.casos.splice(caso_index, 1);

    this.casoSeleccionado = null;

    await this.global.apiCall('cerrar_caso', 'POST', true, data);

  }

  async abrir_caso() {

    if (!this.casoSeleccionado) return;

    if (!confirm('¿Estas seguro?')) return;

    let data = this.clone({ caso: this.casoSeleccionado });

    let result = await this.global.apiCall('abrir_caso', 'POST', true, data);

    if (result.status) {

      let caso_index = this.casos.findIndex(caso => caso.id == this.casoSeleccionado);

      this.casos.splice(caso_index, 1);

      this.casoSeleccionado = null;

    } else {

      alert(result.msg);

    }

  }

  getUltimoMensaje(caso) {

    if (!caso.mensajes) return;

    return caso.mensajes[caso.mensajes.length - 1];

  }

  datetimeToDate(datetime) {

    return new Date(datetime);

  }

  dateToDatetime(date) {

    return date.toISOString();

  }

  toLocale(date) {

    date = new Date(date).toLocaleTimeString('es-ES');

    return date.split(":")[0] + ':' + date.split(":")[1];

  }

  getContactoByTelefono(telefono) {

    let caso = this.casos.find(caso => caso.telefono == telefono);

    if (caso && caso.contacto) {

      let contacto = caso.contacto;

      if (!contacto.imagen) contacto.imagen = 'https://png.pngtree.com/png-vector/20190423/ourmid/pngtree-user-icon-vector-illustration-in-glyph-style-for-any-purpose-png-image_975597.jpg';

      return contacto;

    }

    return {
      cli_nombrecompleto: telefono,
      imagen: 'https://png.pngtree.com/png-vector/20190423/ourmid/pngtree-user-icon-vector-illustration-in-glyph-style-for-any-purpose-png-image_975597.jpg',
      telefono: telefono,
      noEsContacto: true
    };

  }

  telefonoSinPrefijo(telefono) {

    return telefono;

    // return telefono.substr(3);

  }

  scrollToBottom() {

    setTimeout(() => {
      try {
        let elemento = $('#messages')[0];
        elemento.scrollTop = elemento.scrollHeight;
      } catch (err) {
        this.scrollToBottom();
      }
    }, 500);

  }

  getCheckClass(mensaje) {

    if (!mensaje.status) return ['fa-check'];

    if (mensaje.status == 1) return ['fa-check'];

    let status = mensaje.status;

    if (status == 'queued') return ['fa-check'];
    if (status == 'delivered') return ['fa-check-double'];
    if (status == 'again') return ['fa-check-double'];
    if (status == 'read') return ['fa-check-double', 'read'];

  }

  getMensajeByUid(mensajes, uid) {

    if (!mensajes || !uid) return;

    for (let mensaje of mensajes) {

      if (mensaje.uid == uid) return mensaje;

    }

  }

}
