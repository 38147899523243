import { Component, OnInit } from '@angular/core';
import { Global } from '../global';
import { Router } from '@angular/router';
import { Karix } from '../karix';
import * as moment from 'moment';

declare var $;

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {

  dashboard;

  constructor(private global: Global, private router: Router, private karix: Karix) { }

  ngOnDestroy() { }

  async ngOnInit() {

    //Comprobamos si existe un token guardado, y si no ha expirado
    if (!await this.global.checkToken()) {

      //Si no existe redirigimos al login

      this.router.navigateByUrl('/');

    } else {

      await this.get_dashboard();

    }

  }

  async get_dashboard() {

    this.dashboard = await this.global.apiCall('get_dashboard', 'GET', true, null);

    setTimeout(async () => {

      await this.get_dashboard();

    }, 5000);

  }

  getMinutosUltimoMensaje(canal) {

    let secondsToDhms = (seconds) => {

      seconds = Number(seconds);
      var d = Math.floor(seconds / (3600 * 24));
      var h = Math.floor(seconds % (3600 * 24) / 3600);
      var m = Math.floor(seconds % 3600 / 60);
      var s = Math.floor(seconds % 60);

      // var dDisplay = d > 0 ? d + (d == 1 ? " day, " : " days, ") : "";
      // var hDisplay = h > 0 ? h + (h == 1 ? " hour, " : " hours, ") : "";
      // var mDisplay = m > 0 ? m + (m == 1 ? " minute, " : " minutes, ") : "";
      // var sDisplay = s > 0 ? s + (s == 1 ? " second" : " seconds") : "";

      let dias = d + ' dias. ';
      let horas = h + ' h. ';
      let minutos = m + ' m. ';

      let aux = '';

      if (d > 0) aux += dias; else if (h > 0) aux += horas; else if (m > 0) aux += minutos; else aux += 'menos de 1 m.';

      return aux;

    }

    if (!canal) return '';

    if (!canal.fecha_ultimo_mensaje) return '';

    let end = canal.fecha_ultimo_mensaje;

    let now = moment(new Date());
    let duration = moment.duration(now.diff(end));

    //return 'Hace ' + duration.asMinutes().toFixed(0) + ' minutos';
    return 'Hace ' + secondsToDhms(duration.asSeconds());

  }

  drawTable() {

    $('#dataTable').DataTable({
      initComplete: function () {
        this.api().columns().every(function () {
          var column = this;
          var select = $('<select><option value=""></option></select>')
            .appendTo($(column.footer()).empty())
            .on('change', function () {
              var val = $.fn.dataTable.util.escapeRegex(
                $(this).val()
              );

              column
                .search(val ? '^' + val + '$' : '', true, false)
                .draw();
            });

          column.data().unique().sort().each(function (d, j) {
            select.append('<option value="' + d + '">' + d + '</option>')
          });
        });
      }
    });

  }

}
